<template>
  <div class="export-simulation">
    <b-button
      size="sm"
      class="button-export-style"
      title="Télecharger les factures"
      @click.prevent="handleExportSimulation"
    >
      <!-- <div
        class="chargement chargement-loading-icon"
        v-if="getLoaderExportSimulation"
      >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div> -->

      <font-awesome-icon icon="cloud-download-alt" />
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    filter: { required: true }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['exportSimulation']),
    async handleExportSimulation() {
      const response = await this.exportSimulation(this.filter);
      if (response.succes) {
        this.$alert('', response.msg, 'success');
      }
    }
  },
  computed: {
    ...mapGetters(['getLoaderExportSimulation'])
  }
};
</script>
<style scoped lang="scss">
.chargement-loading-icon {
  margin-left: 0px !important;
  .spinner-border {
    width: 0.7rem !important;
    height: 0.7rem !important;
  }
}
</style>
